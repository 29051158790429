<template lang="pug">
#header
	.route-hierarchy(v-if="routeHierarchy.length")
		template(v-for="(routeItem, index) in routeHierarchy")
			router-link(
				:to="routeItem.route"
			) {{ routeItem.displayName }}
			VIcon(name="arrow/right.simple" v-if="index < routeHierarchy.length - 1")
	.left
		h1
			slot
		.label(v-if="label") {{ label }}
	VIcon.header-icon(
		v-if="icon"
		:name="icon"
	)
	slot(name="actions")

.header-details(v-if="lifecycle || showIdentifier")
	.lifecycle(
		v-if="lifecycle"
		:class="{ editable: canEditLifecycle }"
		@click="triggerLifecycleModal"
	)
		VIcon(
			v-if="iconName"
			:name="iconName"
		)
		span {{ $lifecycle.name(original ?? lifecycle) }}
		span(v-if="original") &nbsp; ({{ lifecycle }})
	IdentifierCopy(v-if="showIdentifier") {{ currentRouteId }}
	TheLifecycleModal(
		v-if="showLifecycleModal"
		@closeModal="showLifecycleModal=false"
		@updateLifecycle="lifecycle => $emit('updateLifecycle', lifecycle)"
		:initialState="lifecycle"
		:resource
	)
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import IdentifierCopy from '@/components/base/IdentifierCopy.vue';
import TheLifecycleModal from '@/components/single/TheLifecycleModal.vue';

export default {
	name: 'VTheHeader',
	components: {
		IdentifierCopy,
		TheLifecycleModal
	},
	props: {
		label: {
			type: String,
			required: false,
			default: ''
		},
		icon: {
			type: String,
			required: false,
			default: ''
		},
		currentRouteTitle: {
			type: String,
			required: false,
			default: ''
		},
		showIdentifier: {
			type: Boolean,
			required: false,
			default: false
		},
		contactModuleName: {
			type: String,
			required: false,
			default: null
		},
		lifecycle: {
			type: String,
			required: false,
			default: ''
		},
		canEditLifecycle: {
			type: Boolean,
			required: false,
			default: true
		},
		original: {
			type: String,
			required: false,
			default: null
		},
		resource: {
			type: Object,
			required: false,
			default: null
		}
	},
	emits: ['updateLifecycle'],
	data() {
		return {
			routeHierarchy: [],
			showLifecycleModal: false
		};
	},
	computed: {
		interactableLifecycle() {
			return !!this.lifecycle && this.canEditLifecycle;
		},
		iconName() {
			return this.$lifecycle.icon(this.lifecycle);
		},
		currentRouteId() {
			return this.$route.params.id;
		},
		...mapState({
			contact(state, getters) {
				return getters[`${this.contactModuleName}/contact`];
			}
		}),
		...mapGetters({
			findOrganization: 'organizations/find',
			findTenant: 'tenants/find',
			findMissionProfile: 'missionProfiles/find',
			findSpacecraft: 'spacecrafts/find',
			findStation: 'stations/find',
			findSystem: 'systems/find',
			findDowntime: 'downtimes/find',
			findContact: 'contacts/find'
		})
	},
	created() {
		this.createRouteHierarchy();
	},
	methods: {
		triggerLifecycleModal() {
			if (this.interactableLifecycle) {
				this.showLifecycleModal = true;
			}
		},
		createRouteHierarchy() {
			this.routeHierarchy = [];
			this.addToRouteHierachy(this.$route, this.currentRouteTitle);
		},
		addToRouteHierachy(route, displayName = null) {
			const props = this.getRouteProperties(route, displayName);

			if (route.meta.parentName) {
				let params = {};
				if (props.parentId) {
					params.id = props.parentId;
				}

				const parentRoute = this.$router.resolve({ name: route.meta.parentName, params });
				this.addToRouteHierachy(parentRoute);
			}

			this.routeHierarchy.push({ route, displayName: props.displayName });
		},
		getRouteProperties(route, displayName) {
			if (route.params.id) {
				const subPath = route.fullPath.split('/')[1];
				switch (subPath) {
					case 'mission_profiles': {
						const missionProfile = this.findMissionProfile(route.params.id);
						const parentId = this.isSubRoute(route) ? missionProfile.id : missionProfile.tenant;
						return { displayName: this.getDisplayName(route, displayName, missionProfile), parentId };
					}
					case 'tenants': {
						const tenant = this.findTenant(route.params.id);
						const parentId = this.isSubRoute(route) ? tenant.id : tenant.organizationId;
						return { displayName: this.getDisplayName(route, displayName, tenant), parentId };
					}
					case 'organizations': {
						const organization = this.findOrganization(route.params.id);
						const parentId = this.isSubRoute(route) ? organization.id : null;
						return { displayName: this.getDisplayName(route, displayName, organization), parentId };
					}
					case 'spacecrafts': {
						const spacecraft = this.findSpacecraft(route.params.id);
						const parentId = this.isSubRoute(route) ? spacecraft.id : null;
						return { displayName: this.getDisplayName(route, displayName, spacecraft), parentId };
					}
					case 'stations': {
						const station = this.findStation(route.params.id);
						const parentId = this.isEditRoute(route) ? station.id : null;
						return { displayName: this.getDisplayName(route, displayName, station), parentId };
					}
					case 'systems': {
						const system = this.findSystem(route.params.id);
						const parentId = this.isSubRoute(route) ? system.id : null;
						return { displayName: this.getDisplayName(route, displayName, system), parentId };
					}
					case 'allotments': {
						const allotment = this.findDowntime(route.params.id);
						const parentId = allotment.systemId;
						return { displayName: this.getDisplayName(route, displayName, allotment), parentId };
					}
					case 'contacts': {
						displayName = this.getDisplayName(route, displayName);
						const parentId = this.isEditRoute(route) ? this.contact.id : null;

						if (this.contactModuleName && !this.isEditRoute(route)) {
							displayName = this.contact.spacecraft.name + ' on ' + this.contact.system.name;
						}
						return { displayName, parentId };
					}
				}
			}

			return { displayName: this.getDisplayName(route, displayName, null), parentId: null };
		},
		isEditRoute(route) {
			return route.fullPath.split('/').includes('edit');
		},
		isNewRoute(route) {
			return route.fullPath.split('/').some(subPath => subPath.startsWith('new'));
		},
		isInheritRoute(route) {
			return route.fullPath.split('/').includes('inherit_associations');
		},
		isSubRoute(route) {
			return this.isEditRoute(route) || this.isNewRoute(route) || this.isInheritRoute(route);
		},
		getDisplayName(route, displayName, asset) {
			if (displayName) {
				return displayName;
			}
			if (this.isEditRoute(route)) {
				return 'Edit';
			}
			return asset?.name || route.name;
		}
	}
};
</script>

<style lang="scss" scoped>
#header {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	border-bottom: 1px solid hsl(var(--white20));
	padding-bottom: rem-calc(15);
	margin-bottom: rem-calc(15);

	@media (max-width: $mobile-breakpoint) {
		flex-flow: column;

		.type {
			margin: rem-calc(0 0 30);
		}
	}
}

.route-hierarchy {
	width: 100%;
	color: hsl(var(--white40));
	margin-bottom: rem-calc(5);
	display: flex;
	align-items: center;
	flex-grow: 1;
	order: 2;

	@media (min-width: $mobile-breakpoint) {
		order: unset;
	}

	.icon #{v-deep('.stroke-primary')} {
		stroke: hsl(var(--white40));
	}

	a {
		display: inline-block;
		color: inherit;
		text-decoration: none;
	}
}

.left {
	flex-grow: 1;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	min-width: 0;
	order: 3;

	@media (max-width: $mobile-breakpoint) {
		width: 100%;
	}

	@media (min-width: $mobile-breakpoint) {
		order: unset;
	}
}

h1 {
	display: block;
	margin: rem-calc(0 10 0 0);
	line-height: 1.2;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.label {
	font-size: rem-calc(14);
	font-weight: var(--font-bolder);
	line-height: 1.2;
	padding: rem-calc(5 12);
	border: 1px solid white;
	border-radius: rem-calc(50);
	margin-left: rem-calc(20);
	flex-shrink: 0;
}

.header-details {
	display: flex;
	justify-content: space-between;
	margin-bottom: rem-calc(15);

	@media (max-width: $mobile-breakpoint) {
		flex-flow: column;
	}

	.identifier {
		margin-left: auto;
	}
}

.header-icon {
	margin-bottom: rem-calc(30);
	order: 1;

	@media (min-width: $mobile-breakpoint) {
		order: unset;
		margin: 0;
	}
}

.lifecycle {
	display: flex;
	align-items: center;
	padding: rem-calc(12 16);
	border-radius: var(--global-radius);
	background-color: hsl(var(--white10));
	border: 1px solid transparent;
	user-select: none;

	&.editable {
		cursor: pointer;

		&:hover {
			border-color: hsl(var(--white40));
		}
	}

	.icon {
		margin-right: rem-calc(8);
	}

	& + .identifier {
		margin-left: rem-calc(12);

		@media (max-width: $mobile-breakpoint) {
			margin: rem-calc(10 0 0);
		}
	}
}
</style>
