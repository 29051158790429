<template lang="pug">
VModal(
	@close="closeModal"
	@confirm="updateLifecycleState"
	confirm-button='Save'
	:disableConfirmButton="disableConfirm"
	:confirmButtonTooltip="confirmTooltip"
)
	template(#header) Edit lifecycle state
	VCallout(type="warning") Potential destructive action! Please be sure you know what you are doing.
	.row
		.col.thirds(
			v-for="lifecycle in lifecycles"
			:key="lifecycle"
		)
			.lifecycle-button(
				:class="{ current: initialState === lifecycle.state, newstate: newLifecycleState === lifecycle.state }"
				@click="setNewLifecycleState(lifecycle.state)"
			)
				VIcon(:name="lifecycle.icon")
				.lifecycle {{ $lifecycle.name(lifecycle.state) }}
				.description
					VTooltip(:arrow="false")
						VIcon(name="help")

						template(#content)
							h3 {{ $lifecycle.name(lifecycle.state) }}
							p {{ lifecycle.tooltip }}
</template>

<script>
export default {
	name: 'TheLifecycleModal',
	props: {
		initialState: {
			type: String,
			required: true
		},
		resource: {
			type: Object,
			required: false,
			default: null
		}
	},
	emits: ['closeModal', 'updateLifecycle'],
	data() {
		return {
			newLifecycleState: '',
			lifecycles: [
				{
					state: 'STAGED',
					icon: this.$lifecycle.icon('staged'),
					tooltip: 'In setup means that the asset is internally available, but invisible and unauthorized for use via external APIs'
				},
				{
					state: 'CREATED',
					icon: this.$lifecycle.icon('created'),
					tooltip: 'Operational assets are in nominal state. Available and usable internally and via external APIs.'
				},
				{
					state: 'DEPRECATED',
					icon: this.$lifecycle.icon('deprecated'),
					tooltip: 'Decommissioned assets are unavailable for all actors and invisible via external APIs.'
				}
			]
		};
	},
	computed: {
		disableConfirm() {
			if (this.newLifecycleState === this.initialState || this.newLifecycleState === '') {
				return true;
			}
			if (this.newLifecycleState === 'DEPRECATED' && !this.$rbac.for({ type: 'decommission', id: this.resource.id }).hasPermission) {
				return true;
			}
			if (!this.$rbac.for({ type: 'lifecycle-states', id: this.resource.id }).hasPermission) {
				return true;
			}
			return false;
		},
		confirmTooltip() {
			if (this.newLifecycleState === 'DEPRECATED' && !this.$rbac.for({ type: 'decommission', id: this.resource.id }).hasPermission) {
				return this.$rbac.for({ type: 'decommission', id: this.resource.id }).message;
			}
			if (!this.$rbac.for({ type: 'lifecycle-states', id: this.resource.id }).hasPermission) {
				return this.$rbac.for({ type: 'lifecycle-states', id: this.resource.id }).message;
			}
			return '';
		}
	},
	methods: {
		closeModal() {
			this.$emit('closeModal');
		},
		setNewLifecycleState(state) {
			this.newLifecycleState = state;
		},
		updateLifecycleState() {
			this.$emit('updateLifecycle', this.newLifecycleState);
			this.closeModal();
		}
	}
};
</script>

<style lang="scss" scoped>
.lifecycle-button {
	display: flex;
	align-items: center;
	flex-flow: column;
	padding: rem-calc(20);
	border: 1px solid hsl(var(--white20));
	border-radius: var(--global-radius);
	background-color: hsl(var(--white10));
	cursor: pointer;
	user-select: none;
	position: relative;

	&:hover {
		border-color: hsl(var(--white40));
	}

	&.current {
		border: 1px solid hsl(var(--white60));
		background-color: hsl(var(--white20));
	}

	&.newstate {
		border: 1px solid hsl(var(--orange));
	}

	.icon {
		width: rem-calc(36);
		height: rem-calc(36);
		margin-bottom: rem-calc(15);
	}

	.description {
		position: absolute;
		right: rem-calc(4);
		top: rem-calc(4);

		.icon {
			width: rem-calc(24);
			height: rem-calc(24);
			opacity: 0.3;
		}
	}

	#{v-deep('.description .popper')} {
		width: rem-calc(280);
		display: block;
		padding: rem-calc(10);
	}

	#{v-deep('.description .tooltip')} {
		display: block;

		h3 {
			font-size: rem-calc(17);
			font-weight: 600;
			margin: 0 0 rem-calc(5);
		}

		p {
			margin: 0;
		}
	}
}

.callout {
	margin-bottom: rem-calc(20);
}

.current-text {
	margin-top: rem-calc(10);
}
</style>
