import { manageAPIError } from "@/js/api/builder/api-error";
import msalInstance from '@/js/auth/msal';

import type {
	ServiceNowTickets,
	ServiceNowTicket
} from "@/js/api/types/service-now";

import { webServerConnection } from '@/js/network/axios';

function toTimeStringFormat(time: number): string {
	// assumes time in ms
	const hours = Math.floor(time / (1000 * 60 * 60));
	const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
	const seconds = Math.floor((time % (1000 * 60)) / 1000);

	// Format the time components to add leading zeros if necessary
	const formattedHours = hours.toString().padStart(2, '0');
	const formattedMinutes = minutes.toString().padStart(2, '0');
	const formattedSeconds = seconds.toString().padStart(2, '0');

	// Combine the components into a HH:MM:SS format
	return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const getAllTickets = (): Promise<ServiceNowTickets> =>
	webServerConnection.get('/service-now-tickets')
		.then(response => response.data as ServiceNowTickets)
		.catch(manageAPIError);

const fetchUpdatedAfter = (updatedAfter: string): Promise<ServiceNowTickets> =>
	webServerConnection.get('/service-now-tickets', {
		params: {
			updatedAfter: updatedAfter
		}
	})
		.then(response => response.data as ServiceNowTickets)
		.catch(manageAPIError);

const postTicket = (contact: any, shortDesc: string, desc: string, tenantName: string, parentId: string | null) => {
	const activeAccount = msalInstance!.getActiveAccount();
	const email = activeAccount?.username ?? ''; // email should be store in username
	const lossString = toTimeStringFormat(contact.endTime - contact.startTime);
	const payload = {
		contact_id: contact.id,
		short_description: shortDesc,
		description: desc,
		customer: tenantName,
		antenna: contact.system.name,
		spacecraft: contact.spacecraft.name,
		mission_profile: contact.missionProfile.name,
		service: "LITE System",
		aos: contact.startTime.toISO(),
		los: contact.endTime.toISO(),
		location: contact.station.name,
		loss_amount: lossString,
		customer_id_kogs: contact.missionProfile.tenant,
		caller_id: email,
		channel: "kogs",
		url: window.location.origin + '/contacts/' + contact.id,
		customer_reference_id: contact.externalRef,
		...parentId ? { parent_id: parentId } : {}
	};
	return webServerConnection.post('/service-now-tickets/create', payload)
		.then(response => response.data as ServiceNowTicket)
		.catch(manageAPIError);
};

export { getAllTickets, fetchUpdatedAfter, postTicket };
