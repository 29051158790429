import _ from 'lodash';

import store from '@/store/store';

import { isDeprecated } from '@/js/plugins/lifecycle';
import { compareAlphaNum } from '@/js/utils/compare';

const sortByDeprecated = arr => {
	const [active, deprecated] = _.partition(arr, s => !isDeprecated(s));
	return [...active, ...deprecated];
};

/**
* Sort plugin to mitigate that sortBy from Lodash has trouble sorting strings containing numeric values. For instance SG400 is displayed before SG42.
* Takes an array of objects with a name parameter as input and returns the same list sorted alphanumerically by name.
*/
const sortByAlphaNum = (arr, field = 'name') => {
	return [...arr].sort(compareAlphaNum(field));
};

const sortByConflict = arr => {
	return [...arr].sort((a, b) => a.hasConflict - b.hasConflict);
};

const sortByFavorites = arr => {
	return [...arr].sort((a, b) => b.favoritesCount - a.favoritesCount);
};

const sortByDedicated = (arr) => {
	return [...arr].sort((a, b) => b.isDedicatedToThis - a.isDedicatedToThis || a.isDedicatedToOther - b.isDedicatedToOther);
};

const sortByDisabledForMigration = (arr) => {
	return [...arr].sort((a, b) => a?.disableForMigration - b?.disableForMigration);
};

const sortByLongestAlternative = (arr) => {
	return [...arr].sort((a, b) => {
		if (a?.conflictLevel !== b?.conflictLevel) {
			return a?.conflictLevel - b?.conflictLevel;
		}

		const aLongestAlternative = Math.max(...a.alternatives.map(alternative => alternative?.duration));
		const bLongestAlternative = Math.max(...b.alternatives.map(alternative => alternative?.duration));

		return bLongestAlternative - aLongestAlternative;
	});
};

const sortByDefaultOperator = arr => {
	return [...arr].sort((a, b) => {
		return a.name === 'KSAT' ? -1 : b.name === 'KSAT' ? 1 : 0;
	});
};

const filterObject = (object, filter) => {
	return Object.entries(filter).every(([filterKey, filterValue]) => {
		if (!filterValue) {
			return true;
		}

		const itemValue = object[filterKey];

		if (Array.isArray(filterValue)) {
			if (!filterValue.length) {
				return true;
			}
			if (Array.isArray(itemValue)) { // Two arrays, item must contain one of the array entries in filter
				return itemValue.some(entry => filterValue.find(filterEntry => filterObject(entry, filterEntry)));
			}
			return filterValue.includes(itemValue); // Assumes array of primitives in filter, primitive item property, use includes
		}

		if (itemValue === Object(itemValue)) { // If it's not a primitive, filter iteratively
			return filterObject(itemValue, filterValue);
		}

		return itemValue == filterValue; // Use simple comparison to allow null to be treated the same as undefined
	});
};

const filterByObject = (arr, filter) => {
	// IMPORTANT DIFFERENCE FROM EARLIER FILTERS:
	// _always_ use the exact same name in the filter as the item field you want to check for.
	// E.g want to only show a subset of systems based on their station? filter must be of format:
	// { station: [...] } NOT stations


	// For every item in array, check if it matches any filter property.
	// This check will vary on the permutation of the filter and item types.
	// Seems a bit unoptimized, but only solution obvious to me.

	if (!filter) {
		return arr;
	}

	return arr.filter(item => filterObject(item, filter));
};

const keysToCamel = obj => {
	if (_.isPlainObject(obj)) {
		const n = {};

		Object.keys(obj).forEach((k) => {
			// if key includes a uuid don't convert to camelCase
			const match = k.match(/([0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12})/);
			n[match ? match.input : _.camelCase(k)] = keysToCamel(obj[k]);
		});

		return n;
	}
	if (Array.isArray(obj)) {
		return obj.map((i) => keysToCamel(i));
	}

	return obj;
};

const mapStoreIds = (obj, storeModule) => {
	const hideDeprecated = store.getters[`settings/find`]('hideDeprecated');
	return obj
		.map(id => store.getters[`${storeModule}/find`](id))
		.filter(x => x != null)
		.filter(x => !hideDeprecated || !isDeprecated(x));
};

const inheritObject = (obj) => _.cloneDeep(_.omit(obj, ['id', 'lifecycleState']));

export default () => {
	_.mixin({
		sortByDeprecated,
		sortByAlphaNum,
		sortByConflict,
		sortByFavorites,
		sortByDedicated,
		sortByDisabledForMigration,
		sortByLongestAlternative,
		sortByDefaultOperator,
		keysToCamel,
		mapStoreIds,
		inheritObject,
		filterByObject
	});
};
