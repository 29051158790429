<template lang="pug">
button.button(
	:disabled="isDisabled"
	:type="type"
	@click="click"
)
	VTooltip(:text="message")
		VIcon(
			v-if="icon"
			:name="icon"
		)
		slot
</template>

<script>
export default {
	name: 'VButton',
	props: {
		icon: {
			type: String,
			required: false,
			default: ''
		},
		tooltip: {
			type: String,
			required: false,
			default: ''
		},
		type: {
			type: String,
			required: false,
			default: 'button'
		},
		disabled: {
			type: [Boolean, String],
			required: false,
			default: null
		},
		enabled: {
			type: Boolean,
			required: false,
			default: null
		},
		href: {
			type: String,
			required: false,
			default: null
		}
	},
	computed: {
		isDisabled() {
			if (this.disabled !== null) {
				if (typeof this.disabled === 'string') {
					return true;
				}
				return this.disabled !== false;
			} else if (this.enabled !== null) {
				return this.enabled !== true;
			}

			return false;
		},
		message() {
			if (this.tooltip !== '') {
				return this.tooltip;
			} else if (this.disabled !== null && typeof this.disabled === 'string') {
				return this.disabled;
			}
			return '';
		}
	},
	methods: {
		click() {
			if (this.href === null || this.isDisabled) {
				return;
			}

			this.$router.push(this.href);
		}
	}
};
</script>
