import axios from 'axios';
import msalInstance from '@/js/auth/msal';
import { getScope } from '@/js/auth/auth-config';

import {
	WEBSERVER_URL,
	USER_PATH,
	HEADER_HEIMDALL_TOKEN,
	HEADER_KOGS_TOKEN
} from '@/env';

const axiosUser = axios.create({ baseURL: WEBSERVER_URL + USER_PATH });
axiosUser.interceptors.request.use(async request => {
	request.headers.Authorization = await msalInstance!.idAuthorization();
	request.headers[HEADER_HEIMDALL_TOKEN] = await msalInstance!.accessAuthorization(await getScope('heimdall'));
	request.headers[HEADER_KOGS_TOKEN] = await msalInstance!.accessAuthorization(await getScope('kogs'));

	return request;
});

const webServerConnection = axios.create({ baseURL: WEBSERVER_URL });
webServerConnection.interceptors.request.use(async request => {
	request.headers.Authorization = await msalInstance!.idAuthorization();

	return request;
});

export {
	axiosUser,
	webServerConnection
};
