import axios, { AxiosInstance } from "axios";
import { io } from "socket.io-client";

import { bus } from "@/js/plugins/bus";
import msalInstance from "@/js/auth/msal";
import {
	CONNECT_SOCKETS,
	HEADER_REFETCH_ID,
	HEADER_REFETCH_ID_KEY,
	HEADER_REFETCH_NAMESPACE,
	HEADER_SOCKET_ID,
	NAMESPACE_PREPEND_PROXY,
	PROXY_HEIMDALL,
	PROXY_KOGS,
	PROXY_TO_USE,
	PROXY_URL,
	WEBSERVER_URL
} from "@/env";

import { getScope } from "@/js/auth/auth-config";

const findProxyByScope = async (scope: string) => {
	switch (scope) {
		case await getScope("heimdall"):
			return PROXY_HEIMDALL;
		case await getScope("kogs"):
			return PROXY_KOGS;
		default:
			return null;
	}
};

/*
 * A Connection creates an axios instance with a given path with the common auth interceptor applied.
 * In addition it also creates a socket connection to a namespace that listens on changes made on resources
 * for that path dispatched by the PROXY interceptor.
 *
 * This is a good way to make sure that the socket of the resource is always connected to the right
 * namespace for the given path. It is encouraged to use this construct whenever possible.
 */
export default class {
	proxy: string | null;
	namespace: string;
	socket: any;
	axios: AxiosInstance;
	constructor(
		basePath: string,
		scope: string,
		resource: string | null = null,
		proxy: string | null = null
	) {
		this.proxy = proxy || findProxyByScope(scope) as any;
		this.namespace = "/" + resource;
		if (resource) {
			this.socket = io(
				WEBSERVER_URL + NAMESPACE_PREPEND_PROXY + this.namespace,
				{
					autoConnect: false,
					withCredentials: true,
					transports: ["websocket"],
					auth: async (callback) =>
						callback({ token: await msalInstance!.idToken() })
				}
			);

			bus.on(CONNECT_SOCKETS, async () => {
				const isAuthorized = !!await msalInstance!.idToken();
				if (!this.socket.connected && isAuthorized) {
					this.socket.connect();
				}
			});
		}

		this.axios = axios.create({ baseURL: PROXY_URL + basePath });

		this.axios.interceptors.request.use(async (request: any) => {
			request.headers = {
				...request.headers,
				Authorization: await msalInstance!.accessAuthorization(scope)
			};

			if (this.proxy) {
				request.headers[PROXY_TO_USE] = await this.proxy;
			}

			if (request.method?.toUpperCase() !== "GET") {
				request.headers[HEADER_REFETCH_NAMESPACE] = this.namespace;

				if (this.socket) {
					request.headers[HEADER_SOCKET_ID] = this.socket.id;
				}

				if (request.refetchId) {
					request.headers[HEADER_REFETCH_ID] = request.refetchId;
					delete request.refetchId;
				}

				if (request.refetchIdKey) {
					request.headers[HEADER_REFETCH_ID_KEY] =
						request.refetchIdKey;
					delete request.refetchIdKey;
				}

				if (
					!request.headers[HEADER_REFETCH_ID_KEY] &&
					!request.headers[HEADER_REFETCH_ID]
				) {
					console.warn(
						`${request.method} to ${request.url} send without ${HEADER_REFETCH_ID} or ${HEADER_REFETCH_ID_KEY} header`,
						request.headers
					);
				}
			}
			return request;
		});
	}
}
