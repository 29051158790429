<template lang="pug">
VDropdown.action-dropdown.large(
	label="Actions.."
	:isActive="showActions"
	@toggle="toggleDropdown"
	@hide="showActions = false"
)
	ul.list
		li.list-item(
			:class="{ disabled: !$contact.isEditable(contact) || !$rbac.for({ type: 'contacts', id: contact.id }).hasPermission }"
		)
			VTooltip(
				:text="getActionButtonTooltip('edit')"
			)
				router-link(
					v-if="$rbac.for({ type: 'contacts', id: contact.id }).hasPermission"
					:to="editContactLink(contact)"
				) Edit contact
				span(v-else) Edit contact
		li.list-item(
			:class="{ disabled: !$contact.isEditable(contact) || !$rbac.for({ type: 'contacts', id: contact.id }).hasPermission }"
			@click="$contact.isEditable(contact) ? cancelContact() : null"
		)
			VTooltip(
				:text="getActionButtonTooltip('cancel')"
			) Cancel contact
		li.list-item(
			:class="{ disabled: !$rbac.for({ type: 'contacts', id: contact.id }).hasPermission }"
		)
			VTooltip(
				:text="getActionButtonTooltip()"
				hover
			)
				router-link(
					v-if="$rbac.for({ type: 'contacts', id: contact.id }).hasPermission"
					:to="{ name: 'NewContact' }"
					@click="setParams"
				) Clone contact
				span(v-else) Clone contact
		//- li.list-item.disabled Update TLE TODO
		li.list-item(
			:class="{ disabled: !$contact.isSignable(contact) }"
			@click="$contact.isSignable(contact) ? signContact(contact) : null"
		)
			VTooltip(
				:text="$contact.isSignable(contact) ? null : 'Cannot sign future or ongoing contact'"
			) View signing
		li.list-item(
			v-if="contact.properties.is_internal && $contact.isEditable(contact)"
			@click="removeInternalFlag(contact)"
			) Remove internal flag
		li.list-item(
			:class="{ disabled: !$rbac.for({ type: 'service-now' }).hasPermission }"
			@click="createTicket"
		)
			VTooltip(
				:text="$rbac.for({ type: 'service-now' }).message"
			) Create ServiceNow Incident
ContactsSignModal(ref="signContactModal")
TheCreateServiceNowIncidentsModal(ref="createServiceNowIncidentsModal")
</template>

<script>
import { mapActions } from 'vuex';
import validate, { joi } from '@pv';
import { storeValue } from '@/js/utils/params';

import TheCreateServiceNowIncidentsModal from '../../views/Contacts/components/TheCreateServiceNowIncidentsModal.vue';
import ContactsSignModal from '@/components/feature/ContactsSignModal/ContactsSignModal.vue';

export default {
	name: 'ContactActionsDropdown',
	components: {
		TheCreateServiceNowIncidentsModal,
		ContactsSignModal
	},
	props: {
		contact: {
			type: Object,
			required: true,
			validator: validate(joi.extendedContact())
		},
		emitChanges: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['cancelContact'],
	data() {
		return {
			contactToSign: null,
			showActions: false
		};
	},
	methods: {
		toggleDropdown() {
			this.showActions = !this.showActions;
		},
		async cancelContact() {
			if (this.emitChanges) {
				this.$emit('cancelContact');
				return;
			}

			const confirm = await this.$dialog.confirm({
				message: `Are you sure you want to cancel \n${this.$contact.formatScheduleReply(this.contact)}`
			});

			if (confirm) {
				this.toggleDropdown();

				this.removeContact(this.contact.id)
					.then(() => this.$notify.event('The contact was successfully cancelled', 'success'))
					.catch(() => this.$notify.event('Failed to cancel contact', 'error'));
			}
		},
		editContactLink(contact) {
			return `/contacts/${contact.id}/edit`;
		},
		async removeInternalFlag(contact) {
			const confirm = await this.$dialog.confirm({
				message: `Are you sure you want to remove the internal flag on this contact?\n${this.$contact.formatScheduleReply(this.contact)}`
			});

			if (confirm) {
				this.toggleDropdown();
				this.addReservation(contact)
					.then(() => this.$notify.event('Internal flag was successfully removed', 'success'))
					.catch(() => this.$notify.event('Failed to remove internal flag', 'error'));
			}
		},
		getActionButtonTooltip(action) {
			if (!this.$rbac.for({ type: 'contacts', id: this.contact.id }).hasPermission) {
				return this.$rbac.for({ type: 'contacts', id: this.contact.id }).message;
			}
			if (this.$contact.isEditable(this.contact)) {
				return null;
			}
			if (this.contact.extendedState.isCommitted()) {
				return `Cannot ${action} a committed contact`;
			}
			if (this.contact.extendedState.isCancelled()) {
				return `Cannot ${action} cancelled contact`;
			}
			if (this.$contact.isHistoric(this.contact)) {
				return `Cannot ${action} passed contact`;
			}
			return `Cannot ${action} contact too close to start time`;
		},
		signContact(contact) {
			this.$refs.signContactModal.open({
				contact
			});
		},
		createTicket() {
			this.$refs.createServiceNowIncidentsModal.open([this.contact]);
		},
		setParams() {
			storeValue('params', { inherit: this.contact.id });
		},
		...mapActions({
			removeContact: 'contacts/remove',
			addReservation: 'reservations/add'
		})
	}
};
</script>

<style lang="scss" scoped>
#{v-deep('.tooltip')} {
	>div:not(.popper) {
		justify-content: flex-start;
	}
}
</style>
