<template lang="pug">
.box.current-evaluation(:class="containerClasses")
	.box-body
		component(
			:is="evaluationComponent"
			:evaluation="currentEvaluation"
		)
			template(#actions)
				DropdownList
					DropdownListItem(
						icon="elaborate"
						:enabled="canElaborate && $rbac.for({ type: 'contacts' }).hasPermission"
						@click.prevent="didClickElaborate"
					)
						VTooltip(
							:text="$rbac.for({ type: 'contacts' }).message"
						)
							| Elaborate
					DropdownListItem(
						icon="cog"
						:enabled="canForceOutcome && $rbac.for({ type: 'contacts' }).hasPermission"
						@click.prevent="didClickForceOutcome"
					)
						VTooltip(
								:text="$rbac.for({ type: 'contacts' }).message"
						)
							| Force outcome
	.box-backdrop
		VIcon.past-evaluation-icon(
			v-if="isPastEvaluation"
			name="time"
		)

HistoryDropdown(
	:evaluations="evaluations"
	:evaluationIndex="evaluationIndex"
	@update:evaluationIndex="setEvaluationIndex"
)
</template>

<script>

import delegate from '@/js/utils/delegate';
import { createFilter } from '@/js/utils/filter';

import dig from '@/js/utils/dig';

import BlankEvaluation from './Evaluations/BlankEvaluation.vue';
import Evaluation from './Evaluations/Evaluation.vue';
import HistoryDropdown from './Evaluations/HistoryDropdown.vue';
import { DropdownList, DropdownListItem } from '@/components/base/DropdownList/index';

export default {
	name: 'SignModalEvaluations',
	components: {
		DropdownList,
		DropdownListItem,
		HistoryDropdown
	},
	inject: [
		'$signContactModal'
	],
	props: {
		evaluations: {
			type: Array,
			required: true
		},
		evaluationIndex: {
			type: Number,
			required: false,
			default: 0
		}
	},
	emits: [
		'update:evaluationIndex'
	],
	computed: {
		...delegate('signature', { to: '$signContactModal' }),
		isEmpty() {
			return this.evaluations.length === 0;
		},
		isLatestEvaluation() {
			return this.evaluationIndex === 0;
		},
		isPastEvaluation() {
			return !this.isLatestEvaluation;
		},
		hasActions() {
			return true;
		},
		currentEvaluation() {
			if (this.isEmpty) {
				return null;
			}

			return this.evaluations[this.evaluationIndex];
		},
		outcome() {
			if (this.isEmpty) {
				return 'waiting';
			}

			return this.currentEvaluation.outcome;
		},
		containerClasses() {
			return [
				this.$text.toKebabCase(this.outcome)
			];
		},
		evaluationComponent() {
			if (this.isEmpty) {
				return BlankEvaluation;
			}

			return Evaluation;
		},
		canElaborate() {
			if (this.signatureActionsDisabled) {
				return false;
			}

			return true;
		},
		canForceOutcome() {
			if (!this.isLatestEvaluation) {
				return false;
			}

			return true;
		},
		signatureActionsDisabled() {
			if (!this.isLatestEvaluation) {
				return true;
			}
			if (this.signature.evaluations.length === 0) {
				return true;
			}

			return this.signature.evaluations[0].submissionsUsed
				.map(({ submissionId }) => {
					return this.signature.submissions.find(createFilter({
						id: submissionId
					}));
				})
				.some((submission) => {
					return dig(submission, ['input', 'payload', 'kind']) === 'FORCE';
				});
		}
	},
	methods: {
		setEvaluationIndex(i) {
			this.$emit('update:evaluationIndex', i);
		},
		didClickForceOutcome() {
			this.$signContactModal.openOverrideSubmissionModal();
		},
		didClickElaborate() {
			this.$signContactModal.openOverrideSubmissionModal({
				mode: 'elaborateSignature'
			});
		}
	}
};

</script>

<style lang="scss" scoped>
.box {
	position: relative;

	.box-body {
		position: relative;
		z-index: 2;
	}

	&.anomaly {
		border: 1px solid hsl(var(--red80));
	}

	&.witness-required {
		border: 1px solid hsl(var(--orange));
	}

	&.success {
		border: 1px solid hsl(var(--green));
	}

	&.remark {
		border: 1px solid hsl(var(--green));
	}

	.box-backdrop {
		position: absolute;
		z-index: 1;
		pointer-events: none;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.past-evaluation-icon {
		--icon-size: #{rem-calc(100)};
		--icon-color-primary: hsl(var(--white15));

		position: absolute;
		top: rem-calc(-25);
		right: rem-calc(10);
		z-index: 1;
	}
}
</style>
