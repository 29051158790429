import { Uuid } from "../api/types";
import msalInstance from "@/js/auth/msal";
import { z } from "zod";

export const resourceControlTypes = z.enum([
	"contacts",
	"tenants",
	"organizations",
	"service-now",
	"signature-profiles",
	"spacecrafts",
	"mission-profiles",
	"systems",
	"stations",
	"ksat-keys",
	"org-keys",
	"dedicated",
	"orbit-guard",
	"lifecycle-states",
	"decommission"
]);
type ResourceControlType =
	| "contacts"
	| "tenants"
	| "organizations"
	| "service-now"
	| "signature-profiles"
	| "spacecrafts"
	| "mission-profiles"
	| "systems"
	| "stations"
	| "ksat-keys"
	| "org-keys"
	| "dedicated"
	| "orbit-guard"
	| "lifecycle-states"
	| "decommission"

type ResourcePermissionOptions = {
	type: ResourceControlType | ResourceControlType[];
	id?: Uuid | Uuid[];
};

type ResourcePermissionResponse = {
	hasPermission: true;
	message: null;
} | {
	hasPermission: false;
	message: string;
};

const Roles = {
	InternalRead: "internal:read",
	InternalWrite: "internal:write",
	Admin: "internal:admin"
} as const;

const ADMIN_RESTRICTED_TYPES: ResourceControlType[] = [
	"signature-profiles",
	"ksat-keys",
	"org-keys",
	"dedicated",
	"orbit-guard",
	"decommission"
];

const $rbac = {
	for: (
		options: ResourcePermissionOptions
	): ResourcePermissionResponse => {
		const LOG_RBAC = window.SessionFlags.get<boolean>("LOG_RBAC") ?? false;
		const roles = window.SessionFlags.get<string[]>("ROLES_OVERRIDE") ??
			msalInstance?.getActiveAccount()?.idTokenClaims?.roles ??
			undefined;

		if (roles === undefined) {
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
			LOG_RBAC && console.log({
				required: null,
				roles,
				types: options.type,
				resource: options.id
			});
			return {
				hasPermission: false,
				message: "[ERROR] Failed to get user role"
			};
		}

		if (roles.includes(Roles.Admin)) {
			return {
				hasPermission: true,
				message: null
			};
		}

		const types = [options.type].flat();
		if (types.some((type) => ADMIN_RESTRICTED_TYPES.includes(type))) {
			const restrictedTypes = types.filter((type) =>
				ADMIN_RESTRICTED_TYPES.includes(type)
			);
			// eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
			LOG_RBAC && console.log({
				required: Roles.Admin,
				roles,
				types,
				resource: options.id
			});

			if (Array.isArray(options.id)) {
				return {
					hasPermission: false,
					message:
						`[ADMIN] You don't have permission on these resource types: ${
							restrictedTypes.join(", ")
						}`
				};
			}
			return {
				hasPermission: false,
				message:
					`[ADMIN] You don't have permission on this resource type: ${
						restrictedTypes.join(", ")
					}`
			};
		}

		if (roles.includes(Roles.InternalWrite)) {
			return {
				hasPermission: true,
				message: null
			};
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-unused-expressions
		LOG_RBAC && console.log({
			required: Roles.InternalWrite,
			roles,
			types,
			resource: options.id
		});

		if (Array.isArray(options.id)) {
			return {
				hasPermission: false,
				message:
					`[WRITE] You don't have permission on some or all of these resource types: ${
						types.join(", ")
					}`
			};
		}
		return {
			hasPermission: false,
			message:
				`[WRITE] You don't have permission on this resource type: ${options.type}`
		};
	}
};

export default {
	install: (app: any) => {
		app.config.globalProperties.$rbac = $rbac;
		window.SessionFlags.tryInit("LOG_RBAC", false, "data");
		window.SessionFlags.tryInit("LOG_RBAC", {
			type: "boolean",
			nullable: false
		}, "schema");
		window.SessionFlags.tryInit("ROLES_OVERRIDE", null, "data");
		window.SessionFlags.tryInit("ROLES_OVERRIDE", {
			type: "enum",
			values: ["internal:read", "internal:write", "admin"]
		}, "schema");
	}
};

export { $rbac };
