import { lifecycleStates } from "@/js/utils/data-definitions";
import { $text } from "./text";
const names = {};
Object.values(lifecycleStates).map(({ id, name }) => names[id] = name);

const name = lifecycle => {
	return names[lifecycle] || $text.capitalize(lifecycle);
};

const isOperational = resource => {
	return resource.lifecycleState === 'CREATED';
};

const isDeprecated = resource => {
	return resource.lifecycleState === 'DEPRECATED';
};

const isStaged = resource => {
	return resource.lifecycleState === 'STAGED';
};

const icon = lifecycle => {
	const icons = {
		staged: 'inSetupStatus',
		created: '20/operational',
		deprecated: 'deprecated'
	};
	return icons[lifecycle.toLowerCase()] || '';
};

const tooltip = lifecycle => {
	const tooltip = {
		staged: 'This resource is still in setup',
		created: 'This resource is operational',
		deprecated: 'This resource is decommissioned'
	};
	return tooltip[lifecycle.toLowerCase()] || '';
};

export default {
	install: app => {
		app.config.globalProperties.$lifecycle = {
			name,
			isDeprecated,
			isStaged,
			isOperational,
			icon,
			tooltip
		};
	}
};

export { isDeprecated, isOperational, icon, tooltip };
