import axios from 'axios';
import { WEBSERVER_URL } from '@/env';
import { BrowserCacheLocation } from '@azure/msal-browser';

const tenant = {
	tenantName: "ksatno",
	tenantId: "c08666d8-aaad-427a-b126-289667868626"
};

const msalConfig = {
	auth: {
		clientId: null, // to be injected by the app after id has been received from webserver
		authority: "https://login.microsoftonline.com/" + tenant.tenantId,
		redirectUri: window.origin
	},
	cache: {
		cacheLocation: BrowserCacheLocation.LocalStorage
	},
	system: {
		iframeHashTimeout: 10000 // To attempt circumvention of login issues, up from default 6s to 10s
	}
};

/**
 * Scopes to acquire a token during login phase.
*/
const loginScopes = ["openid", "profile"];

/**
 * Scopes to acquire a token for API calls.
*/

type Scopes = {
	kogs: string,
	heimdall: string
}

let kogsId: string | null = null;
let heimdallId: string | null = null;
let bootPromise: Promise<void> | null = null;

const getScope = async (prop: keyof Scopes) => {
	if (!kogsId && !bootPromise) {
		// Start the boot request and store the promise
		bootPromise = axios.get(WEBSERVER_URL + '/boot')
			.then((bootResponse) => {
				kogsId = bootResponse.data.KOGS_CLIENT_ID;
				heimdallId = bootResponse.data.HEIMDALL_CLIENT_ID;
				bootPromise = null; // Reset the promise after completion
			})
			.catch((error) => {
				bootPromise = null; // Reset the promise in case of error
				throw error; // Re-throw the error to be handled by the caller
			});
	}

	// Wait for the boot request to complete if it's ongoing
	if (bootPromise) {
		await bootPromise;
	}
	switch (prop) {
		case 'kogs':
			return `https://${tenant.tenantName}.onmicrosoft.com/${kogsId}/access`;
		case 'heimdall':
			return `https://${tenant.tenantName}.onmicrosoft.com/${heimdallId}/access`;
	}
};

// increment this number whenever you want to clear session/local storage
const STORAGE_VERSION = 1;
const migrateStorage = () => {
	const currentVersion = localStorage.getItem("storageVersion");

	if (currentVersion === STORAGE_VERSION.toString()) {
		return;
	}

	console.log("Migrating storage from version", currentVersion, "to", STORAGE_VERSION);

	sessionStorage.clear();
	localStorage.clear();

	localStorage.setItem("storageVersion", STORAGE_VERSION.toString());
};

migrateStorage();

export { msalConfig, loginScopes, getScope };
