import { z } from "zod";

const SESSION_FLAG_KEY = "SESSION_FLAGS";

export const SessionSchemaType = z.union([
	z.object({
		type: z.union([
			z.literal("boolean"),
			z.literal("string"),
			z.literal("number"),
			z.literal("array"),
			z.literal("object"),
		])
	}),
	z.object({
		type: z.literal("enum"),
		values: z.array(z.string())
	}),
]).and(
	z.object({
		nullable: z.boolean().optional()
	})
);

type SessionFlagsType = {
	data: Record<string, unknown>;
	schema: Record<
		string,
		z.infer<typeof SessionSchemaType>
	>;
};

function getSessionFlags(): SessionFlagsType {
	const sessionFlags = window.sessionStorage.getItem(SESSION_FLAG_KEY);
	if (sessionFlags === null) {
		return { data: {}, schema: {} };
	}
	return JSON.parse(sessionFlags);
}

function setSessionFlags(
	newValue: SessionFlagsType
): void {
	window.sessionStorage.setItem(
		SESSION_FLAG_KEY,
		JSON.stringify(newValue, null, 2)
	);
}

/**
 * We use session storage to store a JSON object. We expose a Map like interface to the JSON object
 * to make it easier to work with.
 */
export class SessionFlags {
	static all(): SessionFlagsType {
		return getSessionFlags();
	}

	static delete(key: string, index: "data" | "schema" = "data"): boolean {
		const sessionFlags = getSessionFlags();
		const successfully = sessionFlags?.[index]?.[key] !== undefined;
		delete sessionFlags?.[index]?.[key];
		setSessionFlags(sessionFlags);
		return successfully;
	}

	static tryInit<V = unknown>(
		key: string,
		value: V,
		index: "data" | "schema" = "data"
	): boolean {
		if (SessionFlags.has(key, index)) {
			return false;
		}
		SessionFlags.set(key, value, index);
		return true;
	}

	static get<V = unknown>(
		key: string,
		index: "data" | "schema" = "data"
	): V | undefined {
		const sessionFlags = getSessionFlags();
		return sessionFlags?.[index]?.[key] as V;
	}

	static has(key: string, index: "data" | "schema" = "data"): boolean {
		const sessionFlags = getSessionFlags();
		return sessionFlags?.[index]?.[key] !== undefined;
	}

	static set<V = unknown>(
		key: string,
		value: V,
		index: "data" | "schema" = "data"
	) {
		const sessionFlags = getSessionFlags();
		if (sessionFlags[index] === undefined) {
			sessionFlags[index] = {};
		}
		sessionFlags[index][key] = value;
		setSessionFlags(sessionFlags);
	}
}
