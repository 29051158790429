import { createRouter, createWebHistory } from 'vue-router';

import store from '@/store/store';

import TheRouteWrapper from '@/components/HoC/TheRouteWrapper/TheRouteWrapper';
import { monitorBefore } from '@/js/plugins/monitoring';

import styleguideRoutes from '@/views/Styleguide/config/routes';
import { $rbac, resourceControlTypes } from './js/plugins/rbac';
import { storeValue } from './js/utils/params';
import { Uuid } from './js/api/types';

const routes = [
	...styleguideRoutes,
	{
		path: '/',
		redirect: '/issues'
	},
	{
		path: '/monitor',
		name: 'ActivityMonitor',
		component: () => import('@/views/ActivityMonitor/ActivityMonitor.vue'),
		meta: {
			title: 'Activity Monitor'
		}
	},
	{
		path: '/organizations',
		name: 'Organizations',
		component: () => import('@/views/Organizations/OrganizationsAll.vue'),
		meta: {
			title: 'Organizations'
		}
	},
	{
		path: '/organizations/:id',
		name: 'OrganizationDetail',
		component: () => import('@/views/Organizations/OrganizationsDetail.vue'),
		props: true,
		meta: {
			parentName: 'Organizations',
			type: 'Organization',
			favorite: 'organizations'
		}
	},
	{
		path: '/organizations/new',
		name: 'NewOrganization',
		component: () => import('@/views/Organizations/OrganizationsNew.vue'),
		meta: {
			parentName: 'Organizations',
			title: 'New Organization'
		}
	},
	{
		path: '/contacts',
		name: 'Contacts',
		component: () => import('@/views/Contacts/ContactsAll/ContactsAll.vue'),
		meta: {
			title: 'Contacts'
		}
	},
	{
		path: '/contacts/:id',
		props: true,
		name: 'ContactDetail',
		component: () => import('@/views/Contacts/ContactsDetail.vue'),
		meta: {
			parentName: 'Contacts',
			type: 'Contact'
		}
	},
	{
		path: '/contacts/new',
		props: true,
		name: 'NewContact',
		component: () => import('@/views/Contacts/ContactsNew.vue'),
		meta: {
			parentName: 'Contacts',
			title: 'New Contacts'
		}
	},
	{
		path: '/contacts/reallocation',
		props: true,
		name: 'ContactsReallocation',
		component: () => import('@/views/Contacts/ContactsReallocation.vue'),
		meta: {
			parentName: 'Contacts',
			title: 'Contacts Reallocation'
		}
	},
	{
		path: '/contacts/adjust',
		props: true,
		name: 'ContactsAdjust',
		component: () => import('@/views/Contacts/ContactsAdjust.vue'),
		meta: {
			parentName: 'Contacts',
			title: 'Contacts Adjust'
		}
	},
	{
		path: '/contacts/:id/edit',
		props: true,
		name: 'EditContact',
		component: () => import('@/views/Contacts/ContactsEdit.vue'),
		meta: {
			type: 'Contact',
			resourceType: 'contacts',
			parentName: 'ContactDetail',
			title: 'Edit Contact'
		}
	},
	{
		path: '/spacecrafts',
		name: 'Spacecrafts',
		component: () => import('@/views/Spacecrafts/SpacecraftsAll.vue'),
		meta: {
			parentName: 'Assets',
			title: 'Spacecrafts'
		}
	},
	{
		path: '/spacecrafts/:id',
		props: true,
		name: 'SpacecraftDetail',
		component: () => import('@/views/Spacecrafts/SpacecraftsDetail.vue'),
		meta: {
			parentName: 'Spacecrafts',
			type: 'Spacecraft',
			favorite: 'spacecrafts'
		}
	},
	{
		path: '/spacecrafts/new',
		name: 'NewSpacecraft',
		component: () => import('@/views/Spacecrafts/SpacecraftsNew.vue'),
		meta: {
			parentName: 'Spacecrafts',
			title: 'New Spacecraft'
		}
	},
	{
		path: '/spacecrafts/:id/edit',
		name: 'EditSpacecraft',
		component: () => import('@/views/Spacecrafts/SpacecraftsEdit.vue'),
		props: true,
		meta: {
			type: 'Spacecraft',
			resourceType: 'spacecrafts',
			favorite: 'spacecrafts',
			parentName: 'SpacecraftDetail'
		}
	},
	{
		path: '/spacecrafts/:id/inherit_associations',
		props: true,
		name: 'SpacecraftInherit',
		component: () => import('@/views/Spacecrafts/SpacecraftsInherit.vue'),
		meta: {
			parentName: 'SpacecraftDetail'
		}
	},
	{
		path: '/spacecrafts/:id/ephemeris/:tenantId',
		props: true,
		name: 'SpacecraftEphemeris',
		component: () => import('@/views/Spacecrafts/SpacecraftsEphemeris.vue'), // Spacecrafts.Ephemeris,
		meta: {
			parentName: 'SpacecraftDetail'
		}
	},
	{
		path: '/systems',
		name: 'Systems',
		component: () => import('@/views/Systems/SystemsAll.vue'),
		meta: {
			parentName: 'Assets',
			title: 'Systems'
		}
	},
	{
		path: '/systems/:id',
		props: true,
		name: 'SystemDetail',
		component: () => import('@/views/Systems/SystemsDetail/SystemsDetail.vue'),
		meta: {
			parentName: 'Systems',
			type: 'System',
			favorite: 'systems'
		}
	},
	{
		path: '/systems/:id/edit',
		name: 'EditSystem',
		component: () => import('@/views/Systems/SystemsEdit.vue'),
		props: true,
		meta: {
			parentName: 'SystemDetail',
			type: 'System',
			resourceType: 'systems',
			favorite: 'systems'
		}
	},
	{
		path: '/systems/:id/inherit_associations',
		props: true,
		name: 'SystemInherit',
		component: () => import('@/views/Systems/SystemsInherit.vue'),
		meta: {
			parentName: 'SystemDetail'
		}
	},
	{
		path: '/allotments',
		props: true,
		name: 'Allotments',
		component: () => import('@/views/Allotments/AllotmentsAll.vue'),
		meta: {
			title: 'Allotments'
		}
	},
	{
		path: '/systems/new',
		name: 'NewSystem',
		component: () => import('@/views/Systems/SystemsNew.vue'),
		meta: {
			parentName: 'Systems',
			title: 'New System'
		}
	},
	{
		path: '/stations',
		name: 'Stations',
		component: () => import('@/views/Stations/StationsAll.vue'),
		meta: {
			parentName: 'Assets',
			title: 'Stations'
		}
	},
	{
		path: '/stations/:id',
		props: true,
		name: 'StationDetail',
		component: () => import('@/views/Stations/StationsDetail.vue'),
		meta: {
			parentName: 'Stations',
			type: 'Station',
			favorite: 'stations'
		}
	},
	{
		path: '/stations/:id/edit',
		name: 'EditStation',
		component: () => import('@/views/Stations/StationsEdit.vue'),
		props: true,
		meta: {
			title: 'Station',
			favorite: 'stations',
			resourceType: 'stations',
			parentName: 'StationDetail'
		}
	},
	{
		path: '/stations/new',
		name: 'NewStation',
		component: () => import('@/views/Stations/StationsNew.vue'),
		meta: {
			parentName: 'Stations',
			title: 'New Station'
		}
	},
	{
		path: '/tenants/:id',
		name: 'TenantDetail',
		component: () => import('@/views/Tenants/TenantsDetail.vue'),
		props: true,
		meta: {
			parentName: 'OrganizationDetail',
			type: 'Tenant',
			favorite: 'tenants'
		}
	},
	{
		path: '/tenants/:id/edit',
		name: 'EditTenant',
		component: () => import('@/views/Tenants/TenantsEdit.vue'),
		props: true,
		meta: {
			parentName: 'TenantDetail',
			type: 'Tenant',
			resourceType: 'tenants',
			favorite: 'tenants'
		}
	},
	{
		path: '/organizations/:id/new_tenant',
		name: 'NewTenant',
		component: () => import('@/views/Tenants/TenantsNew.vue'),
		props: true,
		meta: {
			parentName: 'OrganizationDetail',
			favorite: 'organizations'
		}
	},
	{
		path: '/mission_profiles/:id',
		props: true,
		name: 'MissionProfileDetail',
		component: () => import('@/views/MissionProfiles/MissionProfilesDetail.vue'),
		meta: {
			type: 'Mission Profile',
			favorite: 'mission_profiles',
			parentName: 'TenantDetail'
		}
	},
	{
		path: '/mission_profiles/:id/edit',
		props: true,
		name: 'EditMissionProfile',
		component: () => import('@/views/MissionProfiles/MissionProfilesEdit.vue'),
		meta: {
			type: 'Mission Profile',
			resourceType: 'mission-profiles',
			favorite: 'mission_profiles',
			parentName: 'MissionProfileDetail'
		}
	},
	{
		path: '/mission_profiles/:id/inherit_associations',
		props: true,
		name: 'MissionProfilesInherit',
		component: () => import('@/views/MissionProfiles/MissionProfilesInherit.vue'),
		meta: {
			parentName: 'MissionProfileDetail'
		}
	},
	{
		path: '/tenants/:id/new_mission_profile',
		name: 'NewMissionProfile',
		component: () => import('@/views/MissionProfiles/MissionProfilesNew.vue'),
		props: true,
		meta: {
			favorite: 'tenants',
			parentName: 'TenantDetail'
		}
	},
	{
		path: '/assets',
		name: 'Assets',
		component: () => import('@/views/Assets/AssetsAll.vue'),
		meta: {
			title: 'Assets'
		}
	},
	{
		path: '/documentation',
		name: 'Documentation',
		component: () => import('@/views/Documentation/DocumentationAll.vue'),
		meta: {
			title: 'Documentation'
		}
	},
	{
		path: '/insight/availability',
		name: 'InsightAvailability',
		component: () => import('@/views/Insight/Availability.vue'),
		meta: {
			title: 'Insight Availability'
		}
	},
	{
		path: '/orbit_guard',
		name: 'Orbit Guard',
		component: () => import('@/views/OrbitGuard/OrbitGuard.vue'),
		meta: {
			title: 'Orbit Guard'
		}
	},
	{
		path: '/issues',
		name: 'Issues',
		component: () => import('@/views/Issues/IssuesPage.vue'),
		meta: {
			title: 'Issues'
		}
	},
	{
		path: '/visualizer',
		name: 'Visualizer',
		component: () => import('@/views/Visualizer/VisualizerView.vue'),
		meta: {
			title: 'Visualizer'
		}
	},
	{
		path: '/signature_profiles',
		name: 'SignatureProfiles',
		component: () => import('@/views/SignatureProfiles/SignatureProfilesAll.vue'),
		meta: {
			title: 'Signature Profiles'
		}
	},
	{
		path: '/ksat_ids',
		name: 'KsatIds',
		component: () => import('@/views/KsatIds/KsatIdsAll.vue'),
		meta: {
			title: 'KSAT IDs'
		}
	},
	{
		path: '/schemas',
		name: 'SchemasOverview',
		component: () => import('@/views/Schemas/Schemas.vue'),
		meta: {
			title: 'Schemas Overview'
		}
	},
	{
		path: '/403',
		name: 'ResourceForbidden',
		component: () => import('@/views/Errors/Forbidden.vue'),
		meta: {
			title: 'Resource Forbidden'
		}
	},
	{
		path: '/:path(.*)*',
		name: 'PageNotFound',
		component: () => import('@/views/Errors/NotFound.vue'),
		meta: {
			title: 'Page Not Found'
		}
	}
];

const router = createRouter({
	history: createWebHistory(),
	routes: routes.map(route => TheRouteWrapper(route))
});

router.beforeEach((to, from, next) => {

	document.title = 'KOGNI';

	if (typeof to.meta.title === 'string') {
		document.title += ` - ${to.meta.title}`;
	}

	// "feature flags", aka naive checking on specific env variables
	if (to.fullPath.includes('orbit_guard') && !store.getters['environment/enableOrbitGuard']) {
		next('PageNotFound');
	}
	if (to.fullPath.includes('visualizer') && !store.getters['environment/enableVisualizer']) {
		next('PageNotFound');
	}

	if (to.meta.favorite && to.params.id) {
		store.dispatch('favorites/incrementAsset', { assetType: to.meta.favorite, id: to.params.id });
	}

	if (to.fullPath.slice(-5) === '/edit') {
		const id = to.params.id as Uuid;
		const type = resourceControlTypes.parse(to.meta.resourceType as string);
		const { hasPermission, message } = $rbac.for({ type, id });
		const from = window.location.href;
		if (!hasPermission) {
			storeValue('unauthorized_redirect', {
				id,
				message,
				buttonText: "Go to details",
				from,
				to: to.fullPath.slice(0, -4)
			});
			next('403');
			return;
		}
	}

	window.scrollTo(0, 0); // Reset scroll to top when navigating to a new page.
	monitorBefore(to);
	next();
});

export default router;
