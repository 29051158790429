<template lang="pug">
li.collapsible-item(
	:class="[lifecycle ? lifecycle.toLowerCase() : '', { 'active': open }]"
	ref="collapsible"
)
	a.collapsible-title(@click="toggle")
		VTooltip(:text="tooltip")
			VIcon.asset-icon(
				v-if="icon"
				:name="icon"
			)
		.text-container
			slot(v-if="$slots?.title" name="title")
			template(v-else)
				span.name {{ name }}
				span.secondary-name(v-if="secondaryName") {{ secondaryName }}
		slot(name="expandedHeader")
		span.amount(v-if="showAmount") {{ amount > 0 ? amount : '0' }}
		VButton.secondary.invert.small.go-to(
			v-if="showButton"
			@click.stop="$router.push(url)"
		)
			| Go to
			VIcon(name="arrow/right")
		VTooltip.lifecycle(
			v-if="lifecycle"
			:text="$lifecycle.name(lifecycle)"
		)
			VIcon(:name="$lifecycle.icon(lifecycle)")
		VIcon.collapsible-icon(name="arrow/right.simple")
	.collapsible-content(v-if="open")
		slot
		footer(v-if="showFooter")
			slot(name="footer")
</template>

<script>
import validate, { joi } from '@pv';

export default {
	name: 'AccordionItem',
	props: {
		name: {
			type: String,
			required: false,
			default: ''
		},
		url: {
			type: String,
			required: false,
			default: ''
		},
		icon: {
			type: String,
			required: false,
			default: null,
			validator: validate(joi.icon())
		},
		amount: {
			type: [Number, String],
			required: false,
			default: null
		},
		lifecycle: {
			type: String,
			required: false,
			default: ''
		},
		secondaryName: {
			type: String,
			required: false,
			default: null
		},
		tooltip: {
			type: String,
			required: false,
			default: ''
		},
		isOpen: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	emits: ['toggled'],
	data() {
		return {
			open: this.isOpen
		};
	},
	computed: {
		showAmount() {
			return !!this.amount;
		},
		showFooter() {
			return !!this.$slots.footer;
		},
		showButton() {
			return !!this.url;
		}
	},
	watch: {
		isOpen() {
			this.open = this.isOpen;
		}
	},
	methods: {
		toggle() {
			this.open = !this.open;
			this.$emit('toggled', this.open);
		}
	}
};
</script>

<style lang="scss">
.collapsible {
	list-style: none;
	padding: 0;

	.collapsible-item {
		border: 1px solid hsl(var(--white20));
		border-radius: var(--global-radius);

		& + .collapsible-item {
			margin-top: rem-calc(10);
		}

		&.active {
			background-color: hsl(var(--dark-purple110));
			box-shadow: inset 0 0 9px hsl(var(--white) / 10%);

			.collapsible-title {
				border-radius: var(--global-radius) var(--global-radius) 0 0;
				background: transparent;

				.collapsible-icon svg {
					transform: rotate(90deg);
				}
			}

			.collapsible-content {
				display: block;
			}
		}

		&.deprecated:not(.active) {
			background-color: hsl(var(--dark-purple110) / var(--deprecated-opacity));
			border-color: hsl(var(--white20) / var(--deprecated-opacity));

			.collapsible-title {
				> *:not(.lifecycle) {
					opacity: var(--deprecated-opacity);
				}
			}
		}

		&.unlinked {
			border-color: hsl(var(--blue) / 80%);

			.asset-icon {
				opacity: 1 !important;

				.stroke-primary {
					stroke: hsl(var(--blue));
				}
			}
		}
	}

	&.inline .collapsible-item {
		border-radius: 0;
		border-width: 1px 0 0;
		margin: 0;

		&:not(.active) .collapsible-title.show-hover:hover {
			background: hsl(var(--white05));
		}
	}

	&.configuration {
		margin-bottom: 1rem;

		.collapsible-item {
			border: none;
			background: transparent;
			box-shadow: none;

			&.active {
				.collapsible-title {
					background: hsl(var(--white05));
					border-radius: var(--global-radius);
				}
			}
		}

		.collapsible-title {
			border: 1px solid hsl(var(--white20));
			border-radius: var(--global-radius);
			background: hsl(var(--white05));

			&.show-hover:hover {
				background: hsl(var(--white10));
			}
		}

		.collapsible-content {
			width: 100%;
			margin-left: 0;
			padding-left: 0;
			padding-bottom: 0;
			border-top: none;
		}
	}

	.collapsible-title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: var(--font-small-title-size);
		padding: rem-calc(15);
		color: inherit;
		border-radius: var(--global-radius);
		user-select: none;
		transition: background-color 0.2s;

		.icon:not([class*='arrow']) {
			margin-right: rem-calc(12);
			opacity: 0.4;
		}

		.amount {
			color: hsl(var(--white60));
			margin-right: rem-calc(10);
		}

		&.show-hover:hover {
			background: hsl(var(--dark-purple110));
			cursor: pointer;
		}

		.go-to {
			margin: rem-calc(0 10);

			.icon {
				margin: rem-calc(0 0 0 5);
			}
		}
	}

	.collapsible-content {
		display: none;
		padding: rem-calc(15 0);
		margin: rem-calc(0 15);
		border-top: 1px solid hsl(var(--white10));
	}

	footer {
		display: flex;
		justify-content: flex-end;
		border-top: 1px solid hsl(var(--white10));
		margin-top: rem-calc(15);
		padding-top: rem-calc(15);
		position: relative;

		> * {
			margin-bottom: 0;
		}
	}
}
</style>
