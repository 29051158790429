import Vuex from "vuex";

import contacts from "./modules/contacts";
import organizations from "./modules/organizations";
import missionProfiles from "./modules/mission-profiles";
import reservationWindows from "./modules/reservation-windows";
import dedicatedSystems from "./modules/dedicated-systems";
import opsUnits from "./modules/ops-units";
import spacecrafts from "./modules/spacecrafts";
import stations from "./modules/stations";
import systems from "./modules/systems";
import tenants from "./modules/tenants";
import downtimes from "./modules/downtimes";
import partnerAllocations from "./modules/partnerAllocations";
import problematics from "./modules/problematics";
import filters from "./modules/filters";
import principals from "./modules/principals";
import brokenIds from "./modules/broken-ids";
import reservations from "./modules/reservations";
import frontendStates from "./modules/frontend-states";
import signatureComponents from "./modules/signature-components";
import signatureProfiles from "./modules/signature-profiles";
import preferences from "./modules/preferences";
import jsonSchemas from "./modules/json-schemas";
import orbitGuard from "./modules/orbit-guard";
import serviceNow from "./modules/service-now";

import migratedAssets from "./modules/migrated-assets";
import releaseLog from "./modules/release-log";
import documentation from "./modules/documentation";

import settings from "./modules/settings";
import user from "./modules/user";
import environment from "./modules/environment";
import time from "./modules/time";
import favorites from "./modules/favorites";

export default new Vuex.Store({
	modules: {
		contacts,
		organizations,
		missionProfiles,
		spacecrafts,
		stations,
		systems,
		tenants,
		environment,
		reservationWindows,
		dedicatedSystems,
		opsUnits,
		downtimes,
		partnerAllocations,
		problematics,
		filters,
		principals,
		releaseLog,
		documentation,
		settings,
		user: user as any,
		time,
		favorites,
		brokenIds,
		migratedAssets,
		reservations,
		frontendStates,
		signatureComponents,
		signatureProfiles,
		preferences: preferences as any,
		jsonSchemas,
		orbitGuard,
		serviceNow
	},
	strict: import.meta.env.MODE !== "production"
});

type EmptyRecord = Record<string, never>;

type CreateStoreMap<
	T extends Record<string, string>,
	Modules extends Record<string, Record<string, unknown>>,
> = {
	[Key in keyof T]: T[Key] extends `${infer Namespace}/${infer Action}`
		? Modules[Namespace][Action] extends never
			? never
			: Modules[Namespace][Action]
		: never
};

export type KogniActions<
	T extends Record<string, /* Action */ string /* Namespace */>,
> = CreateStoreMap<T, {
	contacts: typeof contacts.actions;
	organizations: typeof organizations.actions;
	missionProfiles: typeof missionProfiles.actions;
	spacecrafts: typeof spacecrafts.actions;
	stations: typeof stations.actions;
	systems: typeof systems.actions;
	tenants: typeof tenants.actions;
	environment: typeof environment.actions;
	reservationWindows: typeof reservationWindows.actions;
	dedicatedSystems: typeof dedicatedSystems.actions;
	opsUnits: typeof opsUnits.actions;
	downtimes: typeof downtimes.actions;
	partnerAllocations: typeof partnerAllocations.actions;
	problematics: typeof problematics.actions;
	filters: typeof filters.actions;
	principals: typeof principals.actions;
	releaseLog: EmptyRecord;
	documentation: typeof documentation.actions;
	settings: typeof settings.actions;
	user: typeof user.actions;
	time: typeof time.actions;
	favorites: typeof favorites.actions;
	brokenIds: typeof brokenIds.actions;
	migratedAssets: typeof migratedAssets.actions;
	reservations: typeof reservations.actions;
	frontendStates: typeof frontendStates.actions;
	signatureComponents: typeof signatureComponents.actions;
	signatureProfiles: typeof signatureProfiles.actions;
	preferences: EmptyRecord;
	jsonSchemas: typeof jsonSchemas.actions;
	orbitGuard: typeof orbitGuard.actions;
	serviceNow: typeof serviceNow.actions;
}>;

export type KogniGetters<
	T extends Record<string, /* Getter */ string /* Namespace */>,
> = CreateStoreMap<T, {
	contacts: typeof contacts.getters;
	organizations: typeof organizations.getters;
	missionProfiles: typeof missionProfiles.getters;
	spacecrafts: typeof spacecrafts.getters;
	stations: typeof stations.getters;
	systems: typeof systems.getters;
	tenants: typeof tenants.getters;
	environment: typeof environment.getters;
	reservationWindows: typeof reservationWindows.getters;
	dedicatedSystems: typeof dedicatedSystems.getters;
	opsUnits: typeof opsUnits.getters;
	downtimes: typeof downtimes.getters;
	partnerAllocations: typeof partnerAllocations.getters;
	problematics: typeof problematics.getters;
	filters: typeof filters.getters;
	principals: typeof principals.getters;
	releaseLog: typeof releaseLog.getters;
	documentation: typeof documentation.getters;
	settings: typeof settings.getters;
	user: typeof user.getters;
	time: typeof time.getters;
	favorites: typeof favorites.getters;
	brokenIds: typeof brokenIds.getters;
	migratedAssets: typeof migratedAssets.getters;
	reservations: typeof reservations.getters;
	frontendStates: EmptyRecord;
	signatureComponents: typeof signatureComponents.getters;
	signatureProfiles: typeof signatureProfiles.getters;
	preferences: typeof preferences.getters;
	jsonSchemas: typeof jsonSchemas.getters;
	orbitGuard: typeof orbitGuard.getters;
	serviceNow: typeof serviceNow.getters;
}>;
