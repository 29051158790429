import msalInstance from '@/js/auth/msal';
import { axiosUser } from '@/js/network/axios';
import { bus } from '@/js/plugins/bus';

import { CONNECT_SOCKETS } from '@/env';

export default {
	namespaced: true,
	state: () => ({
		user: null
	}),
	mutations: {
		setUser: (state, payload) => state.user = payload
	},
	getters: {
		user: state => state.user
	},
	actions: {
		authenticate: async ({ commit }) => {
			await msalInstance.login();

			bus.emit(CONNECT_SOCKETS);

			const user = await axiosUser.get('/me/authenticate').then(({ data }) => data);

			const roles = window.SessionFlags.get("ROLES_OVERRIDE") ??
				msalInstance?.getActiveAccount()?.idTokenClaims?.roles ??
				undefined;
			user['roles'] = roles;

			commit('setUser', user);
		},
		logout: () => {
			msalInstance.logoutRedirect();
		}
	}
};
